<template>
<div>
  <b-container>
    <div v-if="loading" class="text-primary font-size-22 d-flex justify-content-center align-items-end p-5">
      <spinner-loading text="loading"/>
    </div>
    <div v-else>
      <div v-if="status === true">
        <p class="text-primary pt-5 font-size-28 text-center font-size-22 font-weight-bold">تم الدفع بنجاح </p>
        <div  class="d-flex justify-content-center align-items-center">
          <img :src="require('@/assets/animation/successPayment.svg')" class="w-300px" />
        </div>
      </div>
      <div v-else-if="status === false">
        <p class="text-danger pt-5 font-size-28 text-center font-size-22 font-weight-bold">حدث خطأ اثناء الدفع </p>
        <div  class="d-flex justify-content-center align-items-center">
          <img :src="require('@/assets/animation/rejectPayment.svg')" class="w-300px" />
        </div>
      </div>
    </div>
  </b-container>
</div>
</template>
<script>
import Payment from '@/Utils/payment'
import { core } from '@/config/pluginInit'
import walletService from '@/modules/userProfile/services/userProfile'
export default {
  data () {
    return {
      loading: true,
      status: ''
    }
  },
  methods: {
    checkStatus () {
      this.loading = true
      const Pay = new Payment('Noon')
      Pay.registerPayment(this.$route.query).then((res) => {
        walletService.successDeposit(res).then(() => {
          this.status = true
        }).catch((err) => {
          core.showSnackbar('error', err.response.data.message)
          this.$router.push('/')
        })
      }).catch(() => {
        this.status = false
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.checkStatus()
  }
}
</script>
<style></style>
